import { Component, HostBinding, Input } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  selector: 'icon-box',
  templateUrl: './icon-box.component.html',
  styleUrls: ['./icon-box.component.scss'],
  standalone: true,
  imports: [SvgIconComponent],
  host: {
    class:
      'bg-grey-alt flex size-12x items-center justify-center shrink-0 text-grey-100',
  },
})
export class IconBoxComponent {
  @Input() public icon: string;
}
