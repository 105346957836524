<ng-container [formGroup]="formGroup">
  <icon-box icon="assets/icons/monochrome-user.svg"></icon-box>
  <app-select
    class="border-transparent flex flex-1"
    [readonly]="readonly"
    [options]="options"
    [underLabel]="underLabelText"
    (change)="onChange($event)"
    [clearable]="false"
    [sortByLabel]="true"
    [for]="for"
  >
  </app-select>
</ng-container>
